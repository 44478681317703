import { jwtDecode } from "jwt-decode";
import { getUnixTime } from "date-fns";
import { REFRESH_URL } from "../constants";

export const removeTokens = () => {
  localStorage.removeItem("accessToken");
  localStorage.removeItem("refreshToken");
  localStorage.removeItem("user");
};

export const fetchResponse = (url, config, accessToken) => {
  const fetchConfig = config;
  // update headers
  fetchConfig.headers = {
    ...fetchConfig.headers,
    Authorization: `Bearer ${accessToken}`
  };
  // proceed with request
  return fetch(url, fetchConfig);
};

const refresh = () => {
  // get refresh token from local storage
  const refreshToken = localStorage.getItem("refreshToken") || false;
  if (!refreshToken) {
    removeTokens();
    return Promise.reject(new Error("No refresh token"));
  }
  const tokenInfo = jwtDecode(refreshToken);
  const isExpired = tokenInfo.exp <= getUnixTime(new Date());
  if (isExpired) {
    // Refesh token expired -> force logout
    removeTokens();
    window.location.reload();
  }
  // update access token
  return fetch(REFRESH_URL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${refreshToken}`
    }
  })
    .then(async res => {
      if (!res.ok) {
        const data = await res.json();
        const err = data?.message || data;
        throw new Error(err);
      }
      return res.json();
    })
    .then(
      res => {
        const { access } = res;
        localStorage.setItem("accessToken", access);
        // update user state
        const decoded = jwtDecode(access);
        const { admin, roles } = decoded;
        const user = JSON.parse(localStorage.getItem("user"));
        localStorage.setItem("user", JSON.stringify({ ...user, admin, roles }));
        return access;
      },
      err => {
        removeTokens();
        return Promise.reject(new Error(err.message));
      }
    );
};

const fetchInstance = async (url, config = {}) => {
  // get current access token from local storage
  let accessToken = localStorage.getItem("accessToken") || false;
  if (!accessToken) {
    removeTokens();
    window.location.reload();
  }
  // decode token to see if expired
  const tokenInfo = jwtDecode(accessToken);
  const isExpired = tokenInfo.exp <= getUnixTime(new Date());
  // if expired -> refresh access token using refresh token
  if (isExpired) {
    accessToken = await refresh();
    // if response from refresh is not a new access token
    if (typeof accessToken !== "string") {
      removeTokens();
      window.location.reload();
    }
  }
  // try fetch request
  let response = await fetchResponse(url, config, accessToken);
  // if initial response in Unauthorized -> try once more to refresh access token
  if (response.statusText === "UNAUTHORIZED") {
    accessToken = await refresh();
    // retry original fetch request
    response = fetchResponse(url, config, accessToken);
  }
  return response;
};

export default fetchInstance;
