import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import { getUnixTime } from "date-fns";
import { jwtDecode } from "jwt-decode";
import {
  logout,
  clearStaticDataCache,
  clearAllSearchData,
  clearLookoutData,
  clearSterlingData,
  clearAllConstraints,
  clearAutoRangingData
} from "../actions";

const PrivateRoute = props => {
  const { onLogout, children } = props;
  const location = useLocation();
  const isUser = !!localStorage.getItem("user");
  useEffect(() => {
    if (!isUser) {
      // logout and redirect to login page
      onLogout();
    }
    // check token valid
    const refreshToken = localStorage.getItem("refreshToken") || false;
    if (!refreshToken) {
      onLogout();
    } else {
      const tokenInfo = jwtDecode(refreshToken);
      const isExpired = tokenInfo.exp <= getUnixTime(new Date());
      if (isExpired) {
        onLogout();
      }
    }
  }, []);

  if (!isUser) {
    // logout and redirect to login page
    return <Navigate to="/login" state={{ from: location }} />;
  }

  return children;
};

PrivateRoute.propTypes = {
  onLogout: PropTypes.func,
  children: PropTypes.node.isRequired
};

PrivateRoute.defaultProps = {
  onLogout: () => {}
};

const mapDispatchToProps = dispatch => ({
  onLogout: () => {
    dispatch(logout());
    // force data refresh on logout
    dispatch(clearAllConstraints());
    dispatch(clearStaticDataCache());
    dispatch(clearAllSearchData());
    dispatch(clearLookoutData());
    dispatch(clearSterlingData());
    dispatch(clearAutoRangingData());
  }
});

export default connect(null, mapDispatchToProps)(PrivateRoute);
