import _ from "lodash";
import { format } from "numfmt";

/**
 * Format numbers from data
 */
export const formatNumber = (number, numberFormat) => {
  try {
    return format(numberFormat, number);
  } catch (error) {
    return number;
  }
};

/**
 * Wrap text over multiple lines (for use in charts)
 */
export const wrapText = (s, w, r = "<br />") =>
  s
    .toString()
    .replace(
      new RegExp(`(?![^\\n]{1,${w}}$)([^\\n]{1,${w}})\\s`, "g"),
      `$1${r}`
    );

/**
 * Convert number format to prefix and suffix (for plotly)
 */
export const getTickFormat = numberFormat => {
  const formatString =
    (numberFormat &&
      numberFormat?.replaceAll("\\", "").replaceAll(".", "").split(/[0#]/)) ||
    [];
  const prefix = formatString[0];
  const suffix = formatString[formatString.length - 1];
  let tickFormat = false;
  if (prefix === "£") {
    tickFormat = "$";
  }
  return { prefix, suffix, format: tickFormat };
};

// helper function for scaleFormatter
export const scaler = (val, mode) => {
  const method = mode === "max" ? _.ceil : _.floor;
  const scale = 10 ** (Math.log10(Math.abs(val)) - 1);
  const base = 5;
  const nearestMultiple = base * scale * method(val / (base * scale));
  return method(nearestMultiple);
};

/**
 * Format scales on multi chart align y axis zero lines
 */
export const scaleFormatter = values => {
  let minScale;
  let maxScale;
  const min = _.min(values);
  const max = _.max(values);
  const absDiff = Math.abs(max) > Math.abs(min);
  if (absDiff) {
    const extent = scaler(max, "max");
    maxScale = extent;
    minScale = -extent;
  } else {
    const extent = scaler(min, "min");
    maxScale = -extent;
    minScale = extent;
  }
  return { minScale, maxScale };
};

/**
 * Get maximum and minimum values for stacked charts
 * Used for scaling for multi charts
 */
export const getStackedMaxMin = chart =>
  chart.x.map((i, k) => {
    // get all values from the different series
    const values = chart.y.map(j => j[1][k]);
    // sum positive values
    const positiveSum = _.sum(values.filter(j => j > 0));
    // sum negative values
    const negativeSum = _.sum(values.filter(j => j <= 0));
    return [positiveSum, negativeSum];
  });

/**
 * Replace text in {curly braces} with values from replacements object
 */
export const replaceText = (text, replacements) => {
  if (!replacements || _.isEmpty(replacements)) return text;
  const textToReplace = text.match(/\{({*[^{}]*}*)\}/g);
  if (textToReplace) {
    let replacedName = text;
    textToReplace.forEach(i => {
      if (replacements[i]) {
        replacedName = replacedName.replace(i, replacements[i]);
      }
    });
    return replacedName;
  }
  return text;
};

/**
 * Align text based on client template - left aligned unless template centered
 */
export const getTextAlign = alignment =>
  alignment === "center" ? "center" : "left";

/**
 * Ensure no duplicate colors between client colors and color map
 */
export const getUniqueColors = (colors, colorMap, isDownload) => {
  if (isDownload) return _.difference(colors, _.values(colorMap));
  return _.difference(
    colors,
    _.values(colorMap).map(i => `#${i}`)
  );
};

/**
 * ensures that an object contains a specified number of keys, filling in any missing keys with empty strings, and returns an array of the object's values.
 */
export const getIndividualMap = (initial, length) => {
  const labels = [];
  for (let i = 0; i < length; i += 1) {
    if (initial[i]) labels.push(initial[i]);
    else labels.push("");
  }
  return labels;
};
