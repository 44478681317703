import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Button, Chip, Stack } from "@mui/material";
import { EditIcon } from "components/Icons";
import { DataGrid, Paper } from "components/BaseComponents";
import ErrorBoundary from "components/ErrorBoundary";
import { getProducts } from "actions";
import { initSearch } from "utils";
import { clientConfig, getChannelConfig } from "config";
import { STERLING_HIGHLIGHT_COLUMNS } from "constants";
import { getColumns, Toolbar } from "./Components";

const styles = {
  highlight: {
    ".top-1": theme => ({ bgcolor: `${theme.palette.positive.main}ff` }),
    ".top-2": theme => ({ bgcolor: `${theme.palette.positive.main}e6` }),
    ".top-3": theme => ({ bgcolor: `${theme.palette.positive.main}cc` }),
    ".top-4": theme => ({ bgcolor: `${theme.palette.positive.main}b3` }),
    ".top-5": theme => ({ bgcolor: `${theme.palette.positive.main}99` }),
    ".top-6": theme => ({ bgcolor: `${theme.palette.positive.main}80` }),
    ".top-7": theme => ({ bgcolor: `${theme.palette.positive.main}66` }),
    ".top-8": theme => ({ bgcolor: `${theme.palette.positive.main}4d` }),
    ".top-9": theme => ({ bgcolor: `${theme.palette.positive.main}33` }),
    ".top-10": theme => ({ bgcolor: `${theme.palette.positive.main}1a` }),
    ".bottom-1": theme => ({ bgcolor: `${theme.palette.negative.main}ff` }),
    ".bottom-2": theme => ({ bgcolor: `${theme.palette.negative.main}e6` }),
    ".bottom-3": theme => ({ bgcolor: `${theme.palette.negative.main}cc` }),
    ".bottom-4": theme => ({ bgcolor: `${theme.palette.negative.main}b3` }),
    ".bottom-5": theme => ({ bgcolor: `${theme.palette.negative.main}99` }),
    ".bottom-6": theme => ({ bgcolor: `${theme.palette.negative.main}80` }),
    ".bottom-7": theme => ({ bgcolor: `${theme.palette.negative.main}66` }),
    ".bottom-8": theme => ({ bgcolor: `${theme.palette.negative.main}4d` }),
    ".bottom-9": theme => ({ bgcolor: `${theme.palette.negative.main}33` }),
    ".bottom-10": theme => ({ bgcolor: `${theme.palette.negative.main}1a` })
  }
};

const Products = props => {
  const {
    channel,
    environments,
    handleBack,
    getOptions,
    options,
    number,
    isLoading,
    rankType,
    consumptionType,
    date,
    client
  } = props;
  const [searchEngine, setSearchEngine] = useState({});
  const [search, setSearch] = useState("");
  const [rows, setRows] = useState(options);
  const [isHighlight, setIsHighlight] = useState(false);
  const { name: channelName } = getChannelConfig(channel);
  const { name: clientName = "" } = clientConfig[client] || {};

  useEffect(() => {
    getOptions(channel, environments, rankType, consumptionType);
  }, []);

  useEffect(() => {
    setSearchEngine(
      initSearch(options, ["product_name", "category", "sector"], {
        shouldSort: false
      })
    );
  }, []);

  useEffect(() => {
    setRows(options);
    setSearchEngine(
      initSearch(options, ["product_name", "category", "sector"], {
        shouldSort: false
      })
    );
  }, [options]);

  const handleChange = val => {
    setSearch(val);
    const results = searchEngine.search(val);
    if (val.length === 0) {
      setRows(options);
    } else {
      setRows(results.map(i => i.item));
    }
  };

  return (
    <Fragment>
      <Paper styles={{ p: 1.5, mb: 2 }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Stack
            direction="row"
            flexWrap="wrap"
            sx={{ columnGap: 1.5, rowGap: 1 }}
          >
            <Chip label={channelName} />
            {environments.map(item => (
              <Chip key={item} label={item} />
            ))}
            <Chip label={rankType?.toUpperCase()} />
            {channel === "ih" && (
              <Chip label={consumptionType?.toUpperCase()} />
            )}
          </Stack>
          <Button
            color="primary"
            variant="outlined"
            size="small"
            onClick={handleBack}
            endIcon={<EditIcon />}
          >
            Edit
          </Button>
        </Stack>
      </Paper>
      <ErrorBoundary>
        <DataGrid
          rows={rows}
          columns={getColumns(isHighlight, clientName)}
          isLoading={isLoading}
          disableRowSelectionOnClick
          disableColumnFilter
          disableColumnSelector
          columnHeaderHeight={65}
          slots={{ toolbar: Toolbar }}
          slotProps={{
            toolbar: {
              search,
              setSearch: val => handleChange(val),
              rankType,
              environments,
              consumptionType,
              isHighlight,
              setHighlight: val => setIsHighlight(val),
              dataDate: channel === "ooh" ? date.cga : date.nielsen
            }
          }}
          getRowClassName={params =>
            params.row.rank <= number && params.row.rank > 0 ? "" : "disabled"
          }
          getCellClassName={params => {
            if (
              !isHighlight ||
              !STERLING_HIGHLIGHT_COLUMNS.includes(params.field) ||
              params.value == null
            )
              return "";
            if (!params.row[`${params.field}_rank`]) return "";
            const val = params.row[`${params.field}_rank`];
            return val > 0 ? `top-${val}` : `bottom-${-val}`;
          }}
          styles={{
            "& .MuiDataGrid-row": {
              "&:nth-of-type(n)": {
                bgcolor: "white",
                "&.disabled": {
                  bgcolor: "grey.100",
                  "&:hover": { bgcolor: "grey.100" }
                }
              }
            },
            ...styles.highlight,
            // move scrollbar to top
            ".MuiDataGrid-scrollbar": { top: 55 }
          }}
          initialState={{ pagination: { paginationModel: { pageSize: 25 } } }}
          columnVisibilityModel={{
            category: false,
            sector: false,
            brand: false,
            pack_type: false,
            offer_type: false,
            value_sales_export: false,
            consumption_type: false,
            units_per_package: false,
            container_material: false,
            pack_size_litres: false
          }}
        />
      </ErrorBoundary>
    </Fragment>
  );
};

Products.propTypes = {
  channel: PropTypes.string,
  environments: PropTypes.arrayOf(PropTypes.string),
  handleBack: PropTypes.func,
  getOptions: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.shape()),
  number: PropTypes.number,
  isLoading: PropTypes.bool,
  rankType: PropTypes.string,
  consumptionType: PropTypes.string,
  date: PropTypes.shape(),
  client: PropTypes.string
};

Products.defaultProps = {
  channel: "",
  environments: [],
  handleBack: () => {},
  getOptions: () => {},
  options: [],
  number: 0,
  isLoading: false,
  rankType: "",
  consumptionType: "",
  date: {},
  client: ""
};

const mapDispatchToProps = dispatch => ({
  getOptions: (channel, environments, rankType, consumptionType) =>
    dispatch(
      getProducts(channel, environments, "view", rankType, consumptionType)
    )
});

export default connect(null, mapDispatchToProps)(Products);
