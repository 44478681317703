import { configureStore } from "@reduxjs/toolkit";
import logger from "redux-logger";
import { thunk } from "redux-thunk";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import rootReducer from "./reducers";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["data", "lookout", "sterling", "reportConstraints"]
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: () =>
    process.env.NODE_ENV === "development" ? [thunk, logger] : [thunk],
  devTools: process.env.NODE_ENV !== "production"
});

export const persistor = persistStore(store);
